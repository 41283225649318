@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .wlcm-table {
    width: 100%;
    display: table;
    border-collapse: collapse;
    table-layout: auto;
    box-sizing: border-box;
    text-indent: initial;
    unicode-bidi: isolate;
  }

  .wlcm-scrollable-table-container {
    position: relative;
    overflow-y: auto;

    .wlcm-table-head {
      .wlcm-table-head-data,
      .wlcm-table-head-actions {
        top: 0;
        position: sticky;
        z-index: 1;
      }
    }
  }
}
