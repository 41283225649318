.napa-time-slot-preview {
  box-shadow:
    0 7px 15px 0 rgba(0, 0, 0, 0.13),
    0 1px 4px 0 rgba(0, 0, 0, 0.11);

  &.cdk-drag-animating {
    transition: transform 100ms cubic-bezier(0, 0, 0.2, 1);
  }
}

.cdk-drag-placeholder {
  opacity: 0;
  pointer-events: none;
}

.napa-grid {
  &.napa-grid-dragging {
    .time-slot-view-container {
      pointer-events: none;
    }

    .time-slot-container-drag-over {
      .time-slot-drop-placeholder-container {
        display: block;
      }
    }
  }
}
