@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .wlcm-phone-input-container {
    width: 100%;
    height: 18px;
    display: flex;
    align-items: center;
  }

  .wlcm-phone-input-container-divider {
    width: 1.5px;
    height: 100%;
    margin: 0 12px;
    background: map-get($theme, field-outline-color);
  }
}
