@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .wlcm-calendar {
    &.mat-calendar {
      width: 224px;
      font-size: 16px;

      &.wlcm-calendar-disabled {
        opacity: 0.5;
        position: relative;
        pointer-events: none;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }

    mat-year-view {
      .mat-calendar-body-label {
        display: none;
      }
    }

    .mat-calendar-body-label {
      opacity: 0;

      &[colspan='7'] {
        display: none;
      }
    }

    .mat-calendar-content {
      padding: 0 !important;
      min-height: 220px;
    }

    .mat-calendar-table-header-divider {
      display: none;
    }

    .mat-calendar-table-header th {
      color: #a4a3a3;
      font-size: 16px;
    }

    .mat-calendar-body-cell-content {
      font-size: 16px;
      border-radius: 8px;
      color: map-get($theme, text-black-color);
    }

    .mat-calendar-body-selected {
      background-color: map-get($theme, selected-option-color);
    }

    .mat-calendar-body-today {
      border: none;
      color: map-get($theme, primary-color);
      font-family: map-get($theme, bold-font-family);
    }

    .mat-calendar-body-in-range {
      &::before {
        background: map-get($theme, selected-option-color);
      }

      .mat-calendar-body-cell-content {
        color: map-get($theme, primary-color);
      }
    }

    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
      > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
        .mat-calendar-body-comparison-identical
      ) {
      background: map-get($theme, selected-option-color);
    }

    .mat-calendar-body-in-preview {
      .mat-calendar-body-cell-preview {
        border: none;
        background: map-get($theme, selected-option-color);
      }
    }

    .mat-calendar-body-selected.mat-calendar-body-today {
      box-shadow: none;
    }

    .cdk-program-focused
      .mat-calendar-body-active
      > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
        .mat-calendar-body-comparison-identical
      ) {
      background-color: transparent;
      border: 1px solid map-get($theme, primary-color);
    }
  }
}
