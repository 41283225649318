@use 'sass:map';
@use '../../../core//utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .wlcm-date-range-picker-panel {
    background: #ffffff;
    box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    padding: 16px;
  }

  .wlcm-date-range-picker-bottom-panel {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 24px;
  }
}
