@font-face {
  font-family: 'LeagueSpartan-Black';
  src: url('/assets/fonts/LeagueSpartan-Black.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'LeagueSpartan-Bold';
  src: url('/assets/fonts/LeagueSpartan-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'LeagueSpartan-ExtraBold';
  src: url('/assets/fonts/LeagueSpartan-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'LeagueSpartan-ExtraLight';
  src: url('/assets/fonts/LeagueSpartan-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'LeagueSpartan-Light';
  src: url('/assets/fonts/LeagueSpartan-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'LeagueSpartan-SemiBold';
  src: url('/assets/fonts/LeagueSpartan-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'LeagueSpartan-Regular';
  src: url('/assets/fonts/LeagueSpartan-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'LeagueSpartan-Medium';
  src: url('/assets/fonts/LeagueSpartan-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'LeagueSpartan-Thin';
  src: url('/assets/fonts/LeagueSpartan-Thin.ttf') format('truetype');
  font-weight: 100;
}
