$before-colors: (
  #1a7db8,
  //1
  #8dc63f,
  //2
  #f47421,
  //3
  #1da395,
  //4
  #a3238f,
  //5
  #f7bd3a,
  //6
  #1da34c,
  //7
  #531ab8,
  //8
  #b42935,
  //9
  #1a3cb8,
  //10
  #23a5b4,
  //11
  #abe062,
  //12
  #f57246,
  //13
  #0e7a86,
  //14
  #83319a,
  //15
  #dd152f,
  //16
  #115ac5,
  //17
  #3fa0e8,
  //18
  #2bc762,
  //19
  #f45621,
  //20
  #31c7a6,
  //21
  #a55cb9,
  //22
  #cbb900,
  //23
  #26845b,
  //24
  #3b4e83,
  //25
  #b95c84,
  //26
  #e83f57,
  //27
  #5d5cb9,
  //28
  #ff513a,
  //29
  #3f84e8,
  //30
  #47c28c,
  //31
  #f7904e,
  //32
  #78499d,
  //33
  #ead600,
  //34
  #f52c45,
  //35
  #5c75b9,
  //36
  #f7b96f,
  //37
  #937acf,
  //38
  #e71b00,
  //39
  #aa7acf //40
);

$background-colors: (
  #e6f3fa,
  //1
  #e2f1cf,
  //2
  #fde3d3,
  //3
  #c1ebe6,
  //4
  #ecd3e8,
  //5
  #fdebc3,
  //6
  #b8e9c9,
  //7
  #d4c6ed,
  //8
  #ecc9cc,
  //9
  #d3daf2,
  //10
  #def2f4,
  //11
  #eafad6,
  //12
  #fbd9cd,
  //13
  #b6d7da,
  //14
  #e6d6eb,
  //15
  #ebb4bb,
  //16
  #c6d8f1,
  //17
  #cfe7f9,
  //18
  #d2fddf,
  //19
  #fcccbc,
  //20
  #e1faf5,
  //21
  #f2e7f5,
  //22
  #f2edbf,
  //23
  #c8e8da,
  //24
  #ced3e0,
  //25
  #f5e7ed,
  //26
  #fad0d6,
  //27
  #e7e7f5,
  //28
  #f9e6e3,
  //29
  #e2edfc,
  //30
  #e5f6ee,
  //31
  #fdede4,
  //32
  #ddd1e6,
  //33
  #faf5be,
  //34
  #ffc5cb,
  //35
  #dee3f1,
  //36
  #fef5ea,
  //37
  #e4def3,
  //38
  #fbddd9,
  //39
  #f5effa //40
);

@mixin color-styles($bg-color, $before-color) {
  background-color: $bg-color;

  wlcm-table-row-actions {
    background-color: inherit;

    &::before {
      background-color: $before-color;
    }
  }
}

@mixin color-styles-event($bg-color, $before-color) {
  background-color: $bg-color;

  &::before {
    background-color: $before-color;
  }
}

@for $i from 1 through 40 {
  .color-#{$i} {
    @include color-styles(nth($background-colors, $i), nth($before-colors, $i));
  }
}

@for $i from 1 through 40 {
  .color {
    &.color-event-#{$i} {
      @include color-styles-event(nth($background-colors, $i), nth($before-colors, $i));
    }
  }
}
