@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .wlcm-button {
    display: flex;
    align-items: center;
    overflow: hidden;
    border: none;
    border-radius: 4px;
    outline: none;
    cursor: pointer;

    &__content {
      display: flex;
      align-items: center;
      font-family: map-get($theme, bold-font-family);
      white-space: nowrap;
      font-size: 14px;
    }

    &--large {
      height: 32px;
      padding: 0 16px;
    }

    &--small {
      height: 24px;
      padding: 0 16px;
    }

    &--primary {
      background-color: map-get($theme, primary-color);
      color: #ffffff;
      &:hover {
        background-color: lighten(map-get($theme, primary-color), 5%);
      }
    }
    &--secondary {
      background-color: map-get($theme, accent-color);
      color: #5a5a5a;
      &:hover {
        background-color: lighten(map-get($theme, accent-color), 2.5%);
      }
    }
    &--accent {
      background-color: map-get($theme, warn-color);
      color: #ffffff;
      &:hover {
        background-color: lighten(map-get($theme, warn-color), 5%);
      }
    }
    &--borderless {
      background-color: transparent;
      color: map-get($theme, primary-color);
    }
    &--paddingless {
      padding-left: 0;
      padding-right: 0;
      background-color: transparent;
      color: map-get($theme, primary-color);

      &.wlcm-button--disabled {
        pointer-events: none;
        user-select: none;
        opacity: 0.5;
      }
    }

    &--loading {
      pointer-events: none;
      opacity: 0.7;

      mat-spinner {
        margin-right: 8px;
      }
    }

    &--disabled {
      &:not(.wlcm-button--paddingless) {
        pointer-events: none;

        .wlcm-button__content {
          opacity: 0.7;
        }
      }
    }
  }
}
