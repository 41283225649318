@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .wlcm-datepicker-select-only-mode {
    cursor: pointer;

    .wlcm-datepicker-input {
      pointer-events: none;
    }
  }
}
