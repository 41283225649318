@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .wlcm-autocomplete {
    width: 100%;
    display: block;
  }

  .wlcm-autocomplete-suffix {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    .wlcm-default-loader {
      width: 18px;
      height: 18px;

      .spinner {
        font-size: 18px;
        font-size: 18px;
      }
    }
  }
}
