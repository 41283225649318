// Common
.napa-checkbox-group {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  gap: 4px;
}

.napa-form-section-title {
  font-family: $font-family-bold;
  font-size: $font-size-lg;
  margin-bottom: 16px;
  line-height: 28px;
}

// Session Form
.napa-form-row {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  width: 700px;
  gap: 16px;
}

.session-form-fake-form-field {
  margin: 22px 0 20px;
}

.napa-form-row-with-actions {
  display: grid;
  align-items: center;
  grid-template-columns: 700px auto;
  width: 100%;
  gap: 32px;
}

// Registration Form
.napa-registration-form-section {
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  width: 740px;

  @media (max-width: $mobile-bp) {
    width: auto;
  }
}

.napa-registration-form-actions {
  display: grid;
  align-items: center;
  grid-template-columns: 740px auto;
  padding-left: 32px;
  width: 100%;
  gap: 24px;

  @media (max-width: $mobile-bp) {
    grid-template-columns: auto min-content;
  }

  .napa-registration-form-section {
    align-items: flex-end;
    padding-bottom: 0;
  }
}

textarea {
  resize: vertical;
}
