@use 'sass:map';
@use '../../../core//utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .wlcm-date-range-picker-body {
    display: grid;
    align-items: flex-start;
    grid-auto-flow: column;
    grid-gap: 32px;
  }
}
