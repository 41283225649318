@use 'sass:map';
@import 'palette';

// Colors
$primary-color: map-get($napa-primary-palette, 500);
$accent-color: map-get($napa-accent-palette, 500);
$warn-color: map-get($napa-warn-palette, 500);

$light-blue-color: #e5eff6;

$color-white: #ffffff;

$body-color: #f5f7f9;
$field-outline-color: #e4eaef;
$focus-field-outline-color: #65a6cd;
$hover-field-outline-color: #747474;
$placeholder-color: #747474;
$field-background: #eceff1;
$inactive-color: #989898;
$light-blue-border-color: #ddeff9;
$radio-button-border-color: #c3cdd6;

$color-text-black: #1b1c1c;
$color-text-gray: #8e9498;
$color-text-light-gray: #a4a3a3;

$dark-gray-color: #1b1c1c;
$dark-pink-color: #a3238f;
$yellow-color: #f7bd3a;
$orange-color: #f47421;
$red-color: #e74c3c;
$green-color: #8dc63f;
$blue-color: #1a7db8;

$light-grey-color: #cbd2d7;
$light-green-color: #ecfdd3;
$light-pink-color: #ffeffd;
$light-blue-color: #edf9fe;
$light-yellow-color: #fff8e8;
$light-orange-color: #fff1e8;
$light-red-color: #ffe8e8;

// Button colors
$button-border-primary-color: #1973a8;
$button-border-light-blue-color: #9cd3f4;

// Fonts
$font-family-black: 'LeagueSpartan-Black';
$font-family-bold: 'LeagueSpartan-Bold';
$font-family-extra-bold: 'LeagueSpartan-ExtraBold';
$font-family-extra-light: 'LeagueSpartan-ExtraLight';
$font-family-light: 'LeagueSpartan-Light';
$font-family-semi-bold: 'LeagueSpartan-SemiBold';
$font-family-regular: 'LeagueSpartan-Regular';
$font-family-medium: 'LeagueSpartan-Medium';
$font-family-thin: 'LeagueSpartan-Thin';

// Font Sizes
$font-size-xxxs: 11px;
$font-size-xxs: 12px;
$font-size-xs: 13px;
$font-size-sm: 14px;

$font-size-md: 16px;

$font-size-lg: 20px;
$font-size-xl: 24px;
$font-size-xxl: 32px;

// Breakpoints
$mobile-bp: 767px;

// Elements
$authorized-content-header-height: 50px;
$card-border-radius: 12px;
