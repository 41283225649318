:root {
  --gjs-canvas-top: 0px;
  --gjs-left-width: 0px;
  --gjs-font-color: $body-color;
}

#gjs {
  border: none;
}

.gjs-one-bg {
  background-color: $body-color;
}

.gjs-two-color {
  color: $color-text-black;
}

.gjs-three-bg {
  color: $color-text-black;
}

.gjs-four-color,
.gjs-four-color-h:hover {
  color: $color-text-black;
}

.gjs-toolbar-items {
  display: flex;
}

.gjs-toolbar-item {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.gjs-sm-sector {
  .gjs-sm-sector-title {
    background-color: $body-color;
  }
}

.gjs-cv-canvas-bg {
  background-color: $color-white;
}
