@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .wlcm-datepicker-panel {
    width: 100%;
    display: block;
    background: #ffffff;
    box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    padding: 16px;
  }
}
