@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .wlcm-checkbox-group {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: max-content;
    gap: 4px;
  }
}
