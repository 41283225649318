@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .mat-mdc-select-panel,
  .mat-mdc-autocomplete-panel {
    padding: 0 !important;
  }

  .wlcm-form-field.wlcm-field-disabled {
    wlcm-select {
      pointer-events: none !important;
    }
  }

  wlcm-select {
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer;

    .wlcm-select-arrow {
      margin-left: 8px;
    }
  }

  // Select
  .wlcm-select-panel,
  .wlcm-autocomplete-panel {
    border: 1px solid map-get($theme, focus-field-outline-color);
    border-radius: 4px !important;
    box-shadow: none !important;
    overflow: hidden;
  }

  mat-select {
    line-height: 18px !important;
    overflow: hidden;

    .mat-mdc-select-arrow-wrapper {
      display: none !important;
    }

    .mat-mdc-select-trigger {
      height: 18px !important;

      .mat-mdc-select-min-line {
        font-size: 14px;
        -webkit-font-smoothing: initial !important;
        letter-spacing: 0.41px;

        &.mat-mdc-select-placeholder {
          color: map-get($theme, placeholder-color);
        }
      }
    }
  }

  // Option
  .mat-mdc-option {
    height: 32px;
    min-height: 32px !important;
    padding: 0 8px !important;

    &.mdc-list-item--selected {
      background-color: map-get($theme, selected-option-color) !important;
    }

    .mdc-list-item__primary-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap !important;
      font-family: map-get($theme, regular-font-family) !important;
      -webkit-font-smoothing: initial !important;
      font-size: 14px !important;
      letter-spacing: 0.41px;
      line-height: 24px;
    }

    &.wlcm-custom-option-content {
      .mdc-list-item__primary-text {
        width: 100%;
      }
    }
  }
}
