@use 'sass:map';
@use '../../../core//utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .wlcm-form-field.wlcm-field-disabled {
    .wlcm-date-range-picker-input {
      pointer-events: none;
    }
  }

  .wlcm-date-range-picker-input {
    width: 100%;
    display: block;
  }

  .wlcm-date-range-picker-input-field-wrapper {
    position: relative;
  }

  .wlcm-date-range-picker-input-field {
    border: none;
    outline: none;
    height: 18px;
    padding: 0;
    font-family: map-get($theme, regular-font-family);
    color: map-get($theme, field-text-color);
    background: transparent;
    max-width: 120px;

    &::placeholder {
      color: map-get($theme, placeholder-color);
    }
  }

  .wlcm-date-range-picker-input-field-mirror {
    position: absolute;
    visibility: hidden;
    pointer-events: none;
  }

  .wlcm-date-range-picker-input-dash {
    width: 12px;
    height: 1px;
    margin: 0 4px;
    background: map-get($theme, field-text-color);
  }
}
