@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .wlcm-table-row-data,
  .wlcm-table-row-actions {
    display: table-cell;
    white-space: nowrap;
    min-width: min-content;
    vertical-align: inherit;
    unicode-bidi: isolate;
    box-sizing: border-box;
    background: #ffffff;
    font-size: 16px;

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .wlcm-table-row-data {
    padding: 16px;
  }

  .wlcm-table-row-actions {
    width: 16px;
    padding: 16px 8px;

    & + .wlcm-table-row-data {
      padding-left: 0px;
    }
  }

  .wlcm-table-row-selected {
    .wlcm-table-row-data {
      color: map-get($theme, primary-color);
    }
  }
}
