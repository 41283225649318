@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .wlcm-step {
    width: 100%;
    display: none;

    &.wlcm-step-active {
      display: block;
    }
  }
}
