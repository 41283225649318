@use '@angular/material' as mat;
@use '@wlcm/angular' as wlcm;

@import './styles/index.scss';
@import './styles/grapes';

@include mat.core();

$napa-front-primary: mat.define-palette($napa-primary-palette);
$napa-front-accent: mat.define-palette($napa-accent-palette);
$napa-front-warn: mat.define-palette($napa-warn-palette);

$napa-front-theme: mat.define-light-theme(
  (
    color: (
      primary: $napa-front-primary,
      accent: $napa-front-accent,
      warn: $napa-front-warn,
    ),
    typography:
      mat.define-typography-config(
        $font-family: $font-family-regular,
      ),
    density: 0,
  )
);

@import './styles/overrides/material-overrides';

@include mat.all-component-themes($napa-front-theme);

@include wlcm.core();

@include wlcm.all-component-themes(
  (
    color: (
      primary: mat.get-color-from-palette($napa-front-primary, 500),
      accent: mat.get-color-from-palette($napa-front-accent, 500),
      warn: mat.get-color-from-palette($napa-front-warn, 500),
    ),
    specific-color: (
      field-outline-color: $field-outline-color,
      focus-field-outline-color: $focus-field-outline-color,
      hover-field-outline-color: $hover-field-outline-color,
      placeholder-color: $placeholder-color,
      selected-option-color: $accent-color,
      field-text-color: $color-text-black,
      text-black-color: $color-text-black,
    ),
    typography: (
      bold: $font-family-bold,
      medium: $font-family-medium,
      regular: $font-family-regular,
    ),
  )
);

@import './styles/overrides/wlcm-overrides';
@import './styles/overrides/text-editor';

html,
body {
  height: 100%;
  background-color: $body-color;
  color: $color-text-black;
}
body {
  margin: 0;
  font-family: $font-family-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
