@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .wlcm-country-code-select {
    cursor: pointer;

    .wlcm-country-code-select-trigger {
      font-size: 14px;
    }
  }
}
