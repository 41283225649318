@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .mat-mdc-radio-group {
    display: grid;
  }

  .mat-mdc-radio-button {
    label {
      font-size: 14px;
      font-family: map-get($theme, regular-font-family);
      color: map-get($theme, text-black-color);
      -webkit-font-smoothing: initial !important;
      font-weight: normal;
    }

    .mat-mdc-radio-touch-target {
      width: 22px;
      height: 22px;
    }

    .mdc-radio {
      width: 16px !important;
      height: 16px !important;
      padding: 6px 6px 6px 0 !important;

      &__background {
        width: 16px !important;
        height: 16px !important;

        &::before {
          display: none !important;
        }
      }

      &__inner-circle {
        top: -2px !important;
        left: -2px !important;
      }
    }

    &:not(.mat-mdc-radio-checked) {
      .mdc-radio__outer-circle {
        border: 1px solid map-get($theme, field-outline-color) !important;
      }
    }

    &.mat-mdc-radio-checked {
      .mdc-radio__outer-circle {
        border-width: 1px !important;
      }

      .mdc-radio__inner-circle {
        transform: scale(0.4) !important;
      }
    }

    .mat-radio-ripple {
      display: none !important;
    }
  }
}
