.napa-common-dialog-title {
  line-height: 24px;
  font-size: $font-size-xl;
}

.napa-medium-dialog-title {
  line-height: 24px;
  font-size: $font-size-md;
}

.custom-add-family {
  .mat-mdc-dialog-surface {
    overflow: hidden;
  }
}
