@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .wlcm-filters-unit {
    &__chevron {
      width: 12px;
      height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: rotateZ(-90deg);
      transition: 225ms cubic-bezier(0.4, 0, 0.2, 1),
        box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  .wlcm-filters-unit-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    width: 100%;

    section {
      display: flex;
      align-items: center;
    }

    .wlcm-entities-count {
      margin-left: 6px;
    }

    .reset-btn {
      margin-right: 12px;
    }

    &__label {
      font-family: map-get($theme, bold-font-family);
      letter-spacing: 0.41px;
      line-height: 24px;
      font-size: 14px;
    }
  }

  .reset-btn {
    border: none;
    outline: none;
    background: white;
    font-family: map-get($theme, bold-font-family);
    color: map-get($theme, primary-color);
    letter-spacing: 0.41px;
    font-size: 14px;
    padding: 0;
  }

  .wlcm-filters-unit-checkboxes {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: max-content;
    gap: 4px;
  }

  .wlcm-filters-unit {
    box-shadow: none !important;

    .wlcm-filters-unit-header {
      background-color: white !important;
    }

    .mat-content {
      margin: 0 !important;
    }

    .mat-expansion-panel-body {
      padding: 8px 0 !important;
    }

    &.mat-expanded {
      .wlcm-filters-unit__chevron {
        color: map-get($theme, primary-color);
        transform: rotateZ(0deg);
      }
    }

    mat-expansion-panel-header {
      padding: 0 !important;
    }
  }
}
