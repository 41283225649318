@use 'sass:map';
@use '../../core/utils' as utils;

@keyframes pass-indicator-animation {
  from {
    right: 100%;
  }

  to {
    right: 0;
  }
}

@keyframes complete-indicator-animation {
  from {
    width: 30%;
    height: 30%;
  }

  to {
    width: calc(100% + 4px);
    height: calc(100% + 4px);
  }
}

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .wlcm-step-header {
    &.wlcm-step-header-passed {
      .wlcm-step-header-complete-indicator {
        border-color: map-get($theme, primary-color);
      }

      .wlcm-step-header-label {
        color: map-get($theme, primary-color);
      }
    }

    &.wlcm-step-header-completed {
      .wlcm-step-header-complete-indicator {
        &::before {
          content: '';
          display: block;
          position: absolute;
          border-radius: 50%;
          background: map-get($theme, primary-color);
          animation: complete-indicator-animation 250ms
            cubic-bezier(0.4, 0, 0.6, 1) forwards;
        }
      }
    }

    &.wlcm-step-header-passed,
    &.wlcm-step-header-completed {
      .wlcm-step-header-pass-indicator {
        &::before {
          content: '';
          left: 0;
          height: 2px;
          display: block;
          position: absolute;
          background: map-get($theme, primary-color);
          animation: pass-indicator-animation 250ms cubic-bezier(0.4, 0, 0.6, 1)
            forwards;
        }
      }
    }

    &.wlcm-step-header-disabled {
      .wlcm-step-header-button {
        opacity: 0.7;
        pointer-events: none;
      }
    }
  }

  .wlcm-step-header-button {
    padding: 0;
    width: 100%;
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    background: none;
    cursor: pointer;
  }

  .wlcm-step-header-label {
    display: inline-block;
    font-family: map-get($theme, bold-font-family);
    color: map-get($theme, field-outline-color);
    margin-bottom: 5px;
    line-height: 20px;
    font-size: 14px;
  }

  .wlcm-step-header-progress-bar {
    width: 100%;
    height: 16px;
    display: flex;
    align-items: center;
  }

  .wlcm-step-header-complete-indicator {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid map-get($theme, field-outline-color);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .wlcm-step-header-pass-indicator {
    flex: 1;
    height: 2px;
    background: map-get($theme, field-outline-color);
    position: relative;
  }
}
