@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .wlcm-entities-count {
    height: 14px;
    min-width: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: map-get($theme, bold-font-family);
    background: map-get($theme, selected-option-color);
    color: map-get($theme, primary-color);
    letter-spacing: 0.32px;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 11px;
    padding: 0 5px;
  }

  .wlcm-hint {
    color: #5a5a5a;
    letter-spacing: 0.35px;
    line-height: 20px;
    font-size: 12px;
  }

  .wlcm-cursor-pointer {
    cursor: pointer;
  }
}
