@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .wlcm-datepicker-input-container {
    width: 100%;
    display: block;
  }
}
