@import 'variables';

h1 {
  font-family: $font-family-bold;
  font-size: $font-size-xxl;
  line-height: 40px;
}

h2 {
  font-family: $font-family-bold;
  font-size: $font-size-xl;
}

h3 {
  font-family: $font-family-bold;
  font-size: $font-size-lg;
  line-height: 28px;
}

h4 {
  font-family: $font-family-bold;
  font-size: $font-size-md;
  line-height: 24px;
}

a {
  font-family: $font-family-bold;
  font-size: $font-size-md;
  color: $primary-color;
  text-decoration: none;
}

.sm-title-text {
}

.md-title-text {
}

.lg-title-text {
  font-family: $font-family-bold;
  font-size: $font-size-xxl;
  line-height: 40px;
}

.label {
  font-family: $font-family-regular;
  font-size: $font-size-md;
  line-height: 24px;
  color: $placeholder-color;
}

.napa-text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.napa-medium-text {
  padding-top: 3px;
  font-size: $font-size-md;
}

.napa-font-family-medium {
  font-family: $font-family-medium;
}

.napa-bold-text {
  padding-top: 3px;
  font-family: $font-family-bold;
}

.napa-semi-bold-text {
  padding-top: 3px;
  font-family: $font-family-semi-bold;
}

.napa-text-placeholder {
  color: $placeholder-color;
}
