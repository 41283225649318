@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .wlcm-table-head-data,
  .wlcm-table-head-actions {
    min-width: min-content;
    display: table-cell;
    vertical-align: inherit;
    unicode-bidi: isolate;
    background: #eceff1;
    white-space: nowrap;
    font-family: map-get($theme, regular-font-family);
    color: map-get($theme, placeholder-color);
    font-size: 14px;
    height: 32px;

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .wlcm-table-head-data {
    padding: 0px 16px;
  }

  .wlcm-table-head-actions {
    width: 16px;
    padding: 0px 8px;

    & + .wlcm-table-head-data {
      padding-left: 0px;
    }
  }
}
