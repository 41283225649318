@import './variables';

// Buttons
.wlcm-button {
  &--primary {
    border: 1px solid $button-border-primary-color;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);

    &:hover:not(.wlcm-button--disabled) {
      border-color: lighten($button-border-primary-color, 5%);
    }

    &.wlcm-button--disabled {
      background-color: $button-border-light-blue-color;
      border-color: $button-border-light-blue-color;
    }
  }

  &--paddingless {
    height: auto !important;
  }

  &--large {
    &:not(.wlcm-button--paddingless) {
      height: 40px;
      border-radius: 12px;
    }

    .wlcm-button__content {
      font-size: $font-size-md;
      font-family: $font-family-bold;
      margin-top: 2px;
    }

    &.wlcm-button--primary {
      padding: 0 16px;
    }
  }
}

// Form field / Input
.wlcm-label {
  font-size: $font-size-md;
  font-family: $font-family-regular;
  margin-bottom: 6px;
  line-height: 16px;
}

.wlcm-form-field {
  &.wlcm-field-focused {
    .wlcm-field-container {
      background: white;
    }
  }
}

.wlcm-field-container {
  padding: 7px 12px;
  border-radius: 12px;
  background: $field-background;
}

.wlcm-form-field-input {
  padding-top: 2px;
  color: $color-text-black;
  font-size: $font-size-md;
  box-sizing: border-box;
  background: inherit;
  line-height: 22px;
}

.napa-filter-base {
  .wlcm-field-error-container {
    display: none;
  }
}

// Select / Autocomplete
.wlcm-select-panel,
.wlcm-autocomplete-panel {
  border-radius: 12px !important;
}

mat-select {
  border-radius: 12px;

  .mat-mdc-select-trigger {
    height: 24px !important;
    padding-top: 2px !important;

    .mat-mdc-select-min-line {
      font-size: $font-size-md;
    }
  }
}

.napa-filter-base {
  .wlcm-form-field {
    .mat-mdc-select-min-line.mat-mdc-select-placeholder {
      color: $color-text-black;
    }

    .wlcm-select-arrow {
      margin-left: 5px;
    }
  }
}

.napa-filter-select-panel {
  min-width: 200px;
}

// Option
.mat-mdc-option {
  height: 40px;
  padding: 0 12px !important;
  position: relative;

  .mat-pseudo-checkbox {
    margin-right: 8px !important;
  }

  &:not(.mat-mdc-option-multiple).mdc-list-item--selected {
    &::before {
      content: '';
      width: 4px;
      height: 16px;
      display: block;
      position: absolute;
      left: 0;
      top: calc(50% - 1px);
      transform: translateY(-50%);
      background: $primary-color;
      border-radius: 16px;
    }
  }

  .mdc-list-item__primary-text {
    font-size: $font-size-md !important;
    letter-spacing: normal !important;
    line-height: 24px;
  }
}

.option-no-bg-selected-state.mdc-list-item--selected {
  background-color: $color-white !important;
}

// Date Range Picker
.wlcm-date-range-picker-input {
  display: flex;
  align-items: center;
  height: 24px;

  .wlcm-date-range-picker-input-field {
    height: 16px;
    padding-top: 4px;
    font-size: $font-size-md;
  }
}

.wlcm-date-range-picker-panel {
  .mat-calendar-body-selected {
    color: $primary-color;
  }

  .mat-calendar-body-cell-content {
    padding-top: 3px;
  }

  .wlcm-calendar-header-period-label {
    margin-top: 3px;
  }
}

// Phone Input
.wlcm-phone-input-container {
  height: 24px;

  .wlcm-phone-input-container-divider {
    background-color: $light-grey-color;
  }
}

// Stepper
.wlcm-step-header {
  .wlcm-step-header-label {
    color: $light-grey-color;
  }

  .wlcm-step-header-complete-indicator {
    border-color: $light-grey-color;
  }

  .wlcm-step-header-pass-indicator {
    background: $light-grey-color;
  }
}

// Search Field
.wlcm-search-field {
  .wlcm-field-prefix {
    margin-top: 2px;
  }
}

// Table
.wlcm-table-head-data,
.wlcm-table-head-actions {
  background-color: $field-background;
}

.wlcm-table-row-data,
.wlcm-table-row-actions {
  &:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  &:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

.wlcm-table-row-data {
  height: 80px;
  box-sizing: border-box;

  @extend .napa-text-ellipsis;
}

.wlcm-table-row-selected {
  .wlcm-table-row-data {
    .napa-text-placeholder {
      color: $primary-color;
    }
  }
}

// Error
.wlcm-error {
  padding: 0 12px;
}

.checklist-required {
  mat-checkbox .mdc-checkbox__background {
    border: solid 1px #1a7db8 !important;
  }
}

.checklist-default {
  mat-checkbox .mdc-checkbox__background {
    border: solid 1px rgb(195, 205, 214) !important;
  }
}
.edit-full-block {
  .wlcm-field-error-container {
    display: none;
  }
}
napa-upsert-user {
  .wlcm-field-container {
    padding: 7px 7px;
  }
}

napa-event-item {
  .wlcm-button__content {
    font-size: $font-size-sm !important;
  }
}

.voice-actions {
  .wlcm-button {
    &.wlcm-button--primary {
      padding: 0 10px !important;
    }
  }
}
