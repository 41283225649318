@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .wlcm-filters-panel {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    box-shadow: 0 10px 32px 0 rgba(38, 41, 45, 0.15);
    background: white;
    border-radius: 4px;

    &__title {
      font-size: 16px;
      letter-spacing: 0.47px;
      font-family: map-get($theme, bold-font-family);
      line-height: 24px;
      margin: 0 0 8px 0;
      margin-bottom: 0;
    }
  }

  .wlcm-filters-panel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .wlcm-filters-panel-units {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: max-content;
    margin-bottom: 16px;
    gap: 8px;
  }

  .wlcm-filters-panel-actions {
    display: flex;
    justify-content: flex-end;
  }
}
