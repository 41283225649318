@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  input {
    color: map-get($theme, field-text-color);
  }

  .wlcm-form-field {
    display: block;

    &.wlcm-field-focused {
      .wlcm-field-container {
        border-color: map-get($theme, focus-field-outline-color);
      }
    }

    &.wlcm-field-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &:not(
        .wlcm-field-focused,
        .wlcm-field-disabled,
        .wlcm-field-touched.wlcm-field-invalid
      ) {
      .wlcm-field-container:hover {
        border-color: map-get($theme, hover-field-outline-color);
      }
    }
  }

  .wlcm-field-touched {
    &.wlcm-field-invalid {
      .wlcm-field-container {
        border-color: map-get($theme, warn-color);
      }

      .wlcm-field-error-container {
        wlcm-error {
          display: block;
        }
      }
    }
  }

  .wlcm-field-prefix {
    &.contains-children {
      margin-right: 8px;
    }
  }

  .wlcm-field-suffix {
    &.contains-children {
      margin-left: 8px;
    }
  }

  .wlcm-field-container {
    display: flex;
    align-items: center;
    padding: 6px 8px;
    border: 1px solid map-get($theme, field-outline-color);
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;
  }

  .wlcm-field-error-container {
    min-height: 20px;

    wlcm-error {
      display: none;
    }
  }

  .wlcm-form-field-input {
    border: 0;
    padding: 0;
    outline: none;
    line-height: 18px;
    width: 100%;
    font-size: 14px;
    letter-spacing: 0.41px;
    font-family: map-get($theme, regular-font-family);

    &:disabled {
      background-color: inherit;
    }
  }
}
