@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  mat-checkbox {
    .mdc-checkbox {
      padding: 0 !important;
    }

    .mat-ripple,
    .mdc-checkbox__ripple {
      display: none !important;
    }

    .mdc-checkbox__background {
      width: 16px;
      height: 16px;
      box-sizing: border-box;
      top: 0 !important;
      left: 0 !important;
      background: #ffffff !important;
      border: 1px solid map-get($theme, field-outline-color) !important;
      border-radius: 4px;

      .mdc-checkbox__checkmark {
        transition: 0s !important;
      }
    }

    .mdc-checkbox__checkmark-path {
      stroke-width: 3px;
      stroke: map-get($theme, primary-color) !important;
      d: path('M 5 11.7 L 11 16.6 L 19 7') !important;
    }

    label {
      padding-left: 8px !important;
      padding-bottom: 2px !important;
      letter-spacing: 0.41px;
      line-height: 24px;
      user-select: none;
      font-size: 14px;
    }

    &.mat-mdc-checkbox-checked {
      .mdc-checkbox__background {
        color: map-get($theme, primary-color) !important;
        border-color: map-get($theme, primary-color) !important;
      }
    }

    .mdc-checkbox__native-control {
      top: 0 !important;
      left: 0 !important;
      height: auto !important;
      width: auto !important;
    }
  }

  .mat-pseudo-checkbox {
    width: 16px !important;
    height: 16px !important;
    border: 1px solid map-get($theme, field-outline-color) !important;
    background: white !important;
    border-radius: 4px !important;

    &.mat-pseudo-checkbox-checked {
      border-color: map-get($theme, primary-color) !important;

      &::after {
        width: 5px !important;
        height: 3px !important;
        border-color: map-get($theme, primary-color);
        top: -2.5px !important;
      }
    }
  }
}
