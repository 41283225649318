@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $color-config: utils.get-color-config($theme_config);
  $primary: map-get($color-config, primary);
  $accent: map-get($color-config, accent);
  $warn: map-get($color-config, warn);

  $typography-config: utils.get-typography-config($theme_config);
  $bold-font-family: utils.get-bold-typography($typography-config);
  $medium-font-family: utils.get-medium-typography($typography-config);
  $regular-font-family: utils.get-regular-typography($typography-config);

  .wlcm-error {
    display: block;
    color: $warn;
    font-size: 13px;
    letter-spacing: 0.35px;
    font-family: $regular-font-family;
    line-height: 20px;
  }
}
