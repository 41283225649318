@use 'sass:map';
@use '../../core/utils' as utils;

@mixin theme($theme-config) {
  $theme: utils.using-theme($theme_config);

  .wlcm-calendar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;

    .wlcm-calendar-header-change-view-button {
      display: grid;
      align-items: center;
      grid-auto-flow: column;
      cursor: pointer;
      background: none;
      outline: none;
      grid-gap: 8px;
      border: none;
      padding: 0;
    }

    .wlcm-calendar-header-period-label {
      font-size: 16px;
      line-height: 24px;
      font-family: map-get($theme, bold-font-family);
      color: map-get($theme, text-black-color);
    }

    .wlcm-calendar-header-button-container {
      width: 24px;
      height: 24px;
    }

    .wlcm-calendar-header-button {
      width: 24px;
      height: 24px;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: map-get($theme, selected-option-color);
      border-radius: 8px;
      cursor: pointer;
      padding: 0;

      &-left {
        transform: rotate(90deg);
      }

      &-right {
        transform: rotate(-90deg);
      }

      &[disabled] {
        pointer-events: none;
      }
    }
  }
}
