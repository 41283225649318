.napa-accordion.mat-accordion .mat-expansion-panel {
  box-shadow: none;
  margin-bottom: 16px;

  &:first-of-type {
    border-top-right-radius: $card-border-radius;
    border-top-left-radius: $card-border-radius;
  }

  &:last-of-type {
    border-bottom-right-radius: $card-border-radius;
    border-bottom-left-radius: $card-border-radius;
  }
}

.tooltip-nowrap {
  white-space: nowrap;
  .mdc-tooltip__surface {
    max-width: unset !important;
  }
}

.wlcm-autocomplete-panel {
  min-width: min-content;
}

.user-role-select-overlay-panel {
  min-width: fit-content;
}
