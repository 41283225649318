.napa-checklist-item {
  display: table-row;
  vertical-align: inherit;
  unicode-bidi: isolate;
  border-color: inherit;
}

.napa-checklist-item-data {
  display: table-cell;
  white-space: nowrap;
  min-width: min-content;
  box-sizing: border-box;
  unicode-bidi: isolate;
  box-sizing: border-box;
  vertical-align: top !important;
  background: #ffffff;
  font-size: 16px;

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.napa-checklist-item-row-cell {
  height: 24px;
  display: flex;
  align-items: center;
}
