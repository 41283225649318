@import './variables';

napa-text-editor {
  .ql-editor {
    max-height: 100%;
    overflow: hidden;
  }

  .isActive {
    .ql-toolbar {
      border: 1px solid $focus-field-outline-color !important;
      border-bottom: none !important;
    }

    .ql-container {
      border: 1px solid $focus-field-outline-color !important;
      border-top: none !important;
    }
  }

  .ql-toolbar {
    border: 1px solid $field-outline-color !important;
    border-radius: 12px 12px 0 0;
    border-bottom: none !important;
    background-color: $field-background;

    .ql-stroke {
      fill: none;
      stroke: $color-text-gray !important;
    }
    .ql-fill {
      fill: $color-text-gray !important;
      stroke: none;
    }
    .ql-picker {
      color: $color-text-gray !important;
    }

    .ql-active {
      color: black !important;
      & .ql-stroke {
        fill: none;
        stroke: $blue-color !important;
      }
      & .ql-fill {
        fill: $blue-color !important;
        stroke: none;
      }
    }
  }

  .ql-container {
    border: 1px solid $field-outline-color !important;
    border-radius: 0 0 12px 12px;
    border-top: none !important;
    background-color: $field-background;
  }
  .ql-formats {
    display: flex !important;
    flex-wrap: wrap !important;
    margin-right: 5px !important;
    justify-content: space-between !important;
    button {
      display: flex !important;
      width: 18px !important;
      height: 18px !important;
      justify-content: space-between !important;
      padding: 0 !important;
    }
  }
}

.add-dialog {
  .ql-picker-options {
    margin-top: -165px !important;
  }
}
.overlay-edit-signature-location {
  .ql-editor {
    min-height: 150px;
  }
}
.ql-picker-label svg {
  content: '';
  background: url('/assets/icons/arrow-down-icon.svg') no-repeat center center;
  background-size: contain;
  display: inline-block;
}
.ql-toolbar .ql-picker .ql-stroke {
  display: none;
}
.ql-snow .ql-picker.ql-header {
  max-width: 75px;
}
.ql-snow .ql-picker-label::before {
  line-height: normal !important;
}
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  width: 12px !important;
  margin-top: -7px !important;
}

.ql-toolbar .ql-picker-item[data-value='1']::before {
  content: 'H1' !important;
}

.ql-toolbar .ql-picker-item[data-value='2']::before {
  content: 'H2' !important;
}

.ql-toolbar .ql-picker-item[data-value='3']::before {
  content: 'H3' !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before {
  content: 'H1' !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before {
  content: 'H2' !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before {
  content: 'H3' !important;
}
