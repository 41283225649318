@import 'variables';

.flex {
  display: flex;
}

.f-flex {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.f-flex-row {
  flex-direction: row !important;
}

.w100 {
  width: 100%;
}

.napa-cursor-pointer {
  cursor: pointer;
}

.napa-vertical-divider {
  width: 1px;
  height: 100%;
  background: $field-outline-color;
}

.napa-table-vertical-divider {
  @extend .napa-vertical-divider;

  height: 48px;
  margin-right: -8px;
}

.napa-sm-vertical-divider {
  @extend .napa-vertical-divider;

  height: 24px;
  background: $light-grey-color;
}

.napa-modality-options-list {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  gap: 8px;
}

.napa-modality-option {
  font-size: $font-size-md;
  font-family: $font-family-medium;
  padding: 6px 8px 4px 8px;
  border-radius: 6px;
  width: min-content;

  &--green {
    color: $green-color;
    background: $light-green-color;
  }

  &--gray {
    color: $color-text-gray;
    background: $field-outline-color;
  }
}

.card {
  padding: 16px;
  background: $color-white;
  border-radius: $card-border-radius;
}

span[inlinesvg] {
  display: block;

  svg {
    display: block;
  }
}
.disabled {
  pointer-events: none;
}

.i-black {
  svg {
    path {
      stroke: $dark-gray-color;
    }
    circle {
      fill: $dark-gray-color;
    }
    line {
      stroke: $button-border-primary-color;
    }
  }
}

.i-primary {
  svg {
    path {
      stroke: $button-border-primary-color;
    }
    circle {
      fill: $button-border-primary-color;
    }
    line {
      stroke: $button-border-primary-color;
    }
  }
}

.i-white {
  svg {
    path {
      stroke: $color-white;
    }
    circle {
      fill: $color-white;
    }
    line {
      stroke: $color-white;
    }
  }
}

.color-primary {
  color: $primary-color;
}
