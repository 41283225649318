@mixin margin-between($px) {
  &:first-child:not(:last-child) {
    margin-right: calc($px / 2);
  }
  &:last-child {
    margin-left: calc($px / 2);
  }
}

@mixin table-column-width($width, $min-width: min-content, $max-width: max-content) {
  width: $width;
  min-width: $min-width;
  max-width: $max-width;
}

@mixin table-coll-width($ntn, $width, $min-width: $width, $max-width: $width) {
  &:nth-of-type(#{$ntn}) {
    width: $width;
    min-width: $min-width;
    max-width: $max-width;
  }
}
